<template>
  <div>
    <div class="flex-def flex-cCenter flex-zBetween">
      <div style="margin-right: 1rem" class="y-pointer y-desc flex-def flex-cCenter">
        <span>平台：</span>
        <span class="num-border flex-def flex-cCenter">可创建<span class="num">{{ uni_scope.uni_total }}</span></span>
        <span class="num-border flex-def flex-cCenter">已创建 <span class="num">{{ uni_scope.used }}</span></span>
        <span style="color: teal" class="flex-def flex-cCenter">剩余创建 <span class="num">{{ uni_scope.surplus }}</span></span>
      </div>
      <el-button @click="editShow=true" type="primary" size="small">添加平台</el-button>
    </div>
    <div style="margin-top: 1rem">
      <el-table :data="list" border style="width: 100%">
        <el-table-column prop="id" label="编号" width="100"></el-table-column>
        <el-table-column prop="name" label="名称" width="180" show-overflow-tooltip></el-table-column>
        <el-table-column label="管理账号" show-overflow-tooltip>
          <template #default="s">
            <span v-if="s.row.account_id > 0">{{s.row.ac_name}}</span>
            <span v-else class="y-desc">未配账号</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template #default="s">
            <el-button @click="$u.toUni(s.row.id)" type="text" size="small">进入平台</el-button>
            <el-button @click="edit(s.row)" type="text" size="small">设置</el-button>
            <el-button @click="agentUser(s.row)" v-if="s.row.account_id === 0" type="text" size="small">分配账号</el-button>
            <el-button @click="accountFail(s.row)" v-if="!s.row.ac_is_founder && s.row.account_id > 0" type="text" size="small" style="color: red">移除账号</el-button>
            <el-popconfirm v-if="!s.row.ac_is_founder" style="margin-left: 1rem" title="确定删除吗？" @confirm="del(s.row)">
              <el-button slot="reference" type="text" size="small" style="color: red">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
        <template #append>
          <el-pagination @current-change="pageChange" :current-page="page" :total="total" :page-size="10"  style="padding: .5rem" background layout="total,prev, pager,next"></el-pagination>
        </template>
      </el-table>
      <el-dialog title="分配账号" :visible.sync="agentUserShow" width="30rem" @close="agentUserClose" destroy-on-close>
        <el-form ref="form" :model="agentUserForm" >
          <el-form-item label="账号名称">
            <y_choose_account v-model="agentUserForm.account_id" style="width: 100%"></y_choose_account>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="agentUserShow = false">取 消</el-button>
          <el-button type="primary" @click="agentUserSubmit">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog title="编辑平台信息" :visible.sync="editShow" width="30rem" @close="editClose">
        <el-form ref="form" :model="editForm" label-width="80px">
          <el-form-item label="平台名称">
            <el-input v-model="editForm.name"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editShow = false">取 消</el-button>
          <el-button type="primary" @click="editSubmit">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import y_choose_account from "@/components/founder/y_choose_account";
export default {
  name: "uni",
  components:{
    y_choose_account,
  },
  data(){
    return{
      agentUserShow:false,
      agentUserForm:{
        id:0,
        account_id:0
      },
      editShow:false,
      editForm:{
        name:""
      },
      list:[],
      page:1,
      total:0,
      uni_scope:{
        uni_total:0,
        used:0,
        surplus:0,
      },
    }
  },
  mounted() {
    this.load();
    // this.loadUniTotal();
  },
  methods:{
    pageChange(cur){
      this.page = cur;
      this.load();
    },
    load(){
      this.$u.api.founder.uniSearch({
        page:this.page,
      }).then(res=>{
        this.list = res.list;
        this.total = res.total;
      })
      this.loadUniTotal();
    },
    edit(item){
      this.editForm = {
        ...item
      };
      this.editShow = true;
    },
    editSubmit(){
      this.$u.api.founder.uniEdit(this.editForm).then(()=>{
        this.$message.success("操作成功");
        this.editShow = false;
        this.load();
      });
    },
    editClose(){
      this.editForm = {
        logo:"",
        name:""
      }
    },
    del(item){
      this.$u.api.founder.uniDel({id:item.id}).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    loadUniTotal(){
      this.$u.api.founder.uniTotal().then(res=>{
        this.uni_scope = res;
      })
    },
    accountFail(item){
      item.account_id = 0;
      this.$u.api.founder.uniEdit(item).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    agentUser(item){
      this.agentUserShow = true;
      this.agentUserForm = {...item};
    },
    agentUserSubmit(){
      this.$u.api.founder.uniEdit(this.agentUserForm).then(()=>{
        this.$message.success("操作成功");
        this.agentUserShow = false;
        this.load();
      })
    },
    agentUserClose(){
      this.agentUserForm = {
        uni_id:0,
        uid:0
      }
    },
  }
}
</script>

<style scoped>
.num-border{
  border-right: 1px solid #ededed;
  margin-right: .5rem;
}
.num{
  padding: 0 .5rem;
  font-size: 1rem;
  font-weight: 600;
}
</style>