<template>
  <el-select v-model="value" :remote-method="remoteMethod" :loading="loading" filterable remote placeholder="输入账号名称">
    <el-option v-for="item in options" :key="item.value" :label="item.username" :value="item.id"></el-option>
  </el-select>
</template>

<script>
export default {
  name: "y_choose_account",
  props:{
    uid: {
      type:Number,
      default:0
    }
  },
  model:{
    prop:"uid",
    event:"change"
  },
  watch:{
    value(e){
      this.$emit("change",e)
    },
  },
  data(){
    return{
      value:"",
      options:[],
      loading: false,
    }
  },
  mounted() {

  },
  methods:{
    remoteMethod(query) {
      if(query === ''){
        return;
      }
      this.loading = true;
      this.$u.api.founder.accountSearch({username:query,page:-1}).then(res=>{
        this.loading = false;
        this.options = res.list;
      })
    },
  }
}
</script>

<style scoped>

</style>